.input-wrapper {
  position: relative;
  margin: 13px 0 0 0; /* To give space for the label */
}

.input-text {
  width: 100%;
  padding: 6px;
  //height: 2.3rem;
  //border: 1px solid #bb0000;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  /* Move the default box-shadow focus style up to match the label's placement */
  //transition: border-color 0.5s;
  box-sizing: border-box;
  display: flex;
  color: var(--text-color);
  background-color: var(--bg-color);
  transition: background-color 1s, color 1s;
}

.input-text:focus {
  box-shadow: 0 0 0 2px var(--highlight-color) !important;
  outline: none;
}

.input-text-focused {
  box-shadow: 0 0 0 2px var(--highlight-color) !important;
}

.input-field {
  display: flex;
  position: relative;
}

.cross-button {
  position: absolute;
  right: 0.3rem;
  top: 0.4rem;
  text-align: end;
  border: none;
  background-color: transparent;
  color: lightgray;
}

//.input-wrapper .css-t3ipsp-control {
//  box-sizing: border-box;
//  box-shadow: 0 0 0 1px dodgerblue;
//  border: 1px solid dodgerblue;
//}
//
//.input-wrapper .css-t3ipsp-control:hover {
//  border-color: dodgerblue;
//}
//
//.input-wrapper .css-13cymwt-control {
//  border: 1px solid #aaa;
//  color: var(--text-color);
//  background-color: var(--bg-color);
//  transition: background-color 1s, color 1s;
//}
//
//.input-wrapper .css-1dimb5e-singleValue {
//  color: var(--text-color);
//  background-color: var(--bg-color);
//  transition: background-color 1s, color 1s !important;
//}
//
//.input-wrapper .css-lnmdiq5-menu {
//  background-color: var(--bg-color) !important;
//}

.select__control {
  background-color: var(--bg-color) !important;
  border-color: var(--border-color) !important;
  transition: background-color 1s, color 1s !important;
}

.select__control--is-focused {
  box-shadow: 0 0 0 2px var(--highlight-color) !important;
}

.select__menu {
  background-color: var(--bg-color) !important;
}

.select__option--is-selected {
  background-color: var(--bg-color3) !important;
}

.select__option--is-focused {
  background-color: var(--bg-color2) !important;
}

.select__single-value {
  color: var(--text-color) !important;
  transition: background-color 1s, color 1s !important;
}

.select__control--is-disabled {
  background-color: var(--disabled-bg-color) !important;
}

.select__control--is-disabled .select__indicators {
  display: none !important;
}

.calendar {
  background-color: var(--bg-color) !important;
}

.calendar div button {
  color: var(--text-color);
}

.react-calendar__tile--now {
  background-color: var(--today-bg) !important;
}

.react-datetime-picker__button svg {
  stroke: var(--text-color) !important;
}

//.inputtext {
//  min-width: 150px;
//  width: 100%;
//  box-shadow: 0 0 0 1px dodgerblue;
//  border-color: dodgerblue;
//}
//
//.inputtext:focus {
//  border: 2px solid dodgerblue;
//  padding: 5px;
//  box-shadow: 0 0;
//  outline: 0;
//  overflow: hidden;
//}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.textlabel {
  position: absolute;
  top: -10px; /* Half of the label's height */
  left: 10px; /* Same as input padding */
  //background-color: #ffffff; /* To overlay the input border */
  padding: 0 5px 0 5px; /* Space on the left and right of the label text */
  font-size: 12px;
  border-radius: 10px;
  color: var(--text-color);
  background-color: var(--bg-color);
  transition: background-color 1s, color 1s !important;
}

.checklabel {
  position: relative;
  top: -2px;
  padding: 0 5px 0 5px; /* Space on the left and right of the label text */
  font-size: 12px;
}

.checkbox-custom {
  background-color: var(--bg-color4) !important;
  border-color: var(--border-color) !important;
  transition: background-color 1s, color 1s !important;
}

///* Customize the label (the container) */
//.check-container {
//  display: block;
//  position: relative;
//  padding-left: 35px;
//  margin-bottom: 12px;
//  cursor: pointer;
//  font-size: 22px;
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}
//
///* Hide the browser's default checkbox */
//.check-container input {
//  position: absolute;
//  opacity: 0;
//  cursor: pointer;
//  height: 0;
//  width: 0;
//}
//
///* Create a custom checkbox */
//.checkmark {
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: 20px;
//  width: 20px;
//  background-color: #eee;
//  border-radius: 0.2rem;
//}
//
///* On mouse-over, add a grey background color */
//.check-container:hover input ~ .checkmark {
//  background-color: #ccc;
//}
//
///* When the checkbox is checked, add a blue background */
//.check-container input:checked ~ .checkmark {
//  background-color: #54a868;
//}
//
///* Create the checkmark/indicator (hidden when not checked) */
//.checkmark:after {
//  content: "";
//  position: absolute;
//  display: none;
//}
//
///* Show the checkmark when checked */
//.check-container input:checked ~ .checkmark:after {
//  display: block;
//}
//
///* Style the checkmark/indicator */
//.check-container .checkmark:after {
//  left: 9px;
//  top: 5px;
//  width: 5px;
//  height: 10px;
//  border: solid white;
//  border-width: 0 3px 3px 0;
//  -webkit-transform: rotate(45deg);
//  -ms-transform: rotate(45deg);
//  transform: rotate(45deg);
//}