//@import '../styles/variables';
//@import '../styles/mixins';

.navibar {
  //width: 200px;
  //height: 100%;
  //position: fixed;
  //top: 0;
  //right: 0;
  //padding-top: 20px;
  //@include navbar-styles;
  //text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  & {
    transition: background-color 1s, color 1s;
  }

  .theme-switcher {
    //margin-bottom: 30px;

    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
      margin-right: 10px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--selector-bg-color);
        transition: 0.4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: '';
          height: 16px;
          width: 16px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      input:checked + .slider {
        background-color: var(--selector-bg-color-active);
      }

      input:checked + .slider:before {
        transform: translateX(16px);
      }
    }

  }

  span {
    font-size: 16px;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin: 10px 0;

      a {
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        transition: background-color 1s;
      }
    }
  }
  .mode-desc {
    width: 70px;
    text-align: right;
    font-weight: normal;
    font-size: smaller;
    color: var(--navbar-text)
  }
}

.selector-container {
  display: flex;
  align-items: center;
  justify-content: center;
  //vertical-align: center;
}

.switch-selector {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 14px;
  //margin-right: 10px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--selector-bg-color);
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      height: 8px;
      width: 8px;
      left: 5px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: var(--selector-bg-color-active);
    //background-color: #425c95;
  }

  input:checked + .slider:before {
    transform: translateX(12px);
  }
}

.selector-desc {
  font-weight: normal;
  font-size: smaller;
  color: var(--navbar-text-active)
}

.selector-desc-active {
  font-weight: normal;
  font-size: smaller;
  color: var(--navbar-text)
}
