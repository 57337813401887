// Theme Colors
:root {
  --bg-color: #ffffff; // global bg
  --bg-color2: #f0f0f0; // even tasks bg
  --bg-color3: #e0e0e0; // task description bg
  --bg-color4: #f5f5f5; // checkbox bg
  --disabled-bg-color: #e3e3e3;
  --selector-bg-color: #b1b1b1;
  --selector-bg-color-active: #4063a6;
  --table-header-bg-color: #5b657a;
  --table-header-text-color: #ffffff;
  --text-color: #333333;
  --text-color2: #333333;
  --text-color3: #333333;
  --ok-text-color: #2d8745; // end-date color
  --warning-text-color: #aa4a4a; // end-date color
  --link-text-color: #3665c7;
  --page-title-color: #5b657a;
  --border-color: #cbcbcb;
  --highlight-color: #1c8dfb;
  --today-bg: #efedaf;
  --navbar-hover: #e0e0e0;
  --navbar-text: #4288ea;
  --navbar-text-hover: #6b96bf;
  --navbar-text-active: #26518e;
  --navbar-text-active-hover: #0576b5;
  --primary-but-bg: #5478c5;
  --primary-but-text: #eeeeee;
  --secondary-but-bg: #3665c7;
  --secondary-but-text: #efedaf;
  --warning-but-bg: #f17f1f;
  --warning-but-text: #2e2e2d;
  --success-but-bg: #2d8745;
  --success-but-text: #efedaf;
  --danger-but-bg: #af0f0f;
  --danger-but-text: #efedaf;
  --light-but-bg: #454545;
  --light-but-text: #eeeeee;
  --info-but-bg: #3665c7;
  --info-but-text: #efedaf;
  --extralight-but-bg: #f3f3f3;
  --extralight-but-text: #717171;
  --but-box-shadow-color: #282c34;
  --react-select-bg-color: #ffffff;
  --react-select-text-color: #000000;
  --react-select-border-color: #cccccc;
  --react-select-hover-color: #f0f0f0;
  --md-checkbox-bg-color: #999999;
  --md-checkbox-bg-color-checked: #999999;
  --md-checkbox-border-color: #bbbbbb;
  --md-checkbox-color: #ffffff;
}

.theme-dark {
  --bg-color: #2c2c2c;
  --bg-color2: #3a3a3a;
  --bg-color3: #4e4e4e;
  --bg-color4: #8e8e8e;
  --disabled-bg-color: #3c3c3c;
  --selector-bg-color: #535353;
  --selector-bg-color-active: #4063a6;
  --table-header-bg-color: #42495a;
  --table-header-text-color: #93b2d6;
  --text-color: #cccccc;
  --text-color2: #ffffff;
  --text-color3: #ffffff;
  --ok-text-color: #78a184;
  --warning-text-color: #bc8787;
  --link-text-color: #68aff6;
  --page-title-color: #8c91aa;
  --border-color: #606060;
  --highlight-color: #f3f3f3;
  --today-bg: #7e7343;
  --navbar-hover: #3a3a3a;
  --navbar-text: #6194d8;
  --navbar-text-hover: #5c85c1;
  --navbar-text-active: #b3d3ff;
  --navbar-text-active-hover: #739eb6;
  --primary-but-bg: #a8bddd;
  --primary-but-text: #1c1c1c;
  --secondary-but-bg: #3665c7;
  --secondary-but-text: #efedaf;
  --warning-but-bg: #774e3e;
  --warning-but-text: #efedaf;
  --success-but-bg: #377551;
  --success-but-text: #fcf9ab;
  --danger-but-bg: #7e3939;
  --danger-but-text: #eeeeee;
  --light-but-bg: #454545;
  --light-but-text: #eeeeee;
  --info-but-bg: #3665c7;
  --info-but-text: #efedaf;
  --extralight-but-bg: #acacac;
  --extralight-but-text: #3e3e3e;
  --but-box-shadow-color: #a5a5a5;
  --react-select-bg-color: #2c2c2c;
  --react-select-text-color: #ffffff;
  --react-select-border-color: #444444;
  --react-select-hover-color: #3a3a3a;
  --md-checkbox-bg-color: #dddddd;
  --md-checkbox-bg-color-checked: #dddddd;
  --md-checkbox-border-color: #aaaaaa;
  --md-checkbox-color: #333333;
}

$transition-duration: 1s;

// Default Theme
//$theme: $dark-theme !default;

//$light-button-bg: #f0f0f0;
//$light-button-text: #000;

//$dark-button-bg: #333;
//$dark-button-text: #fff;