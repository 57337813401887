@import 'variables';

// Light Theme
.navibar {
  //background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color $transition-duration, color $transition-duration;

  a {
    color: var(--text-color);

    &:hover {
      background-color: var(--navbar-hover);
    }
  }

}

.themed-component {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
  transition: background-color $transition-duration, color $transition-duration;
}

.themed-modal {
  background-color: var(--bg-color2);

  input {
    @extend .themed-component;
    background-color: var(--bg-color);
    min-width: 0 !important;
  }

  label {
    @extend .themed-component;
    background-color: var(--bg-color);
    border-top: 1px solid var(--bg-color3);
  }
}

.themed-modal .css-19bb58m {
  input {
    width: 0 !important;
  }
}

body {
  @extend .themed-component;
  //background-color: var(--bg-color2) !important;
  //color: var(--text-color2) !important;
  //transition: background-color $transition-duration, color $transition-duration;
}

.primary-but {
  background-color: var(--primary-but-bg) !important;
  color: var(--primary-but-text) !important;
  transition: background-color $transition-duration, color $transition-duration;
}

.success-but {
  background-color: var(--success-but-bg) !important;
  color: var(--success-but-text) !important;
  transition: background-color $transition-duration, color $transition-duration;
}

.danger-but {
  background-color: var(--danger-but-bg) !important;
  color: var(--danger-but-text) !important;
  transition: background-color $transition-duration, color $transition-duration;
}

.page-header {
  font-family: "Titillium Web", serif;
  font-size: 2rem;
  font-weight: bold;
}

/*###################################################################*/
/* Modal Window */
.modalwindow {
  //position: relative;
  position: fixed;
  //padding-top: 50px;
  padding: 50px 0 200px 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: baseline;
  justify-content: center;
  z-index: 1020;
  overflow: scroll;
}

.modalcontent {
  margin: auto;
  max-width: 500px;
  min-width: 350px;
  background-color: #fff;
  border: #282c34 solid 1px;
  border-radius: 10px;
  padding: 10px;
}

.modalheader {
  padding: 0px;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}

.modaltitle {
  padding-top: 0px;
  //margin: 0;
}

.modalbody {
  position: relative;
  padding: 0 20px 0 20px;
}

.modalfooter {
  padding: 10px;
  display: flex;
  justify-content: center;
}