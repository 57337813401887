@font-face {
    font-family: "Aclonica";
    src: url("./Aclonica/Aclonica-Regular.ttf");
}

@font-face {
    font-family: "Berkshire Swash";
    src: url("./Berkshire_Swash/BerkshireSwash-Regular.ttf");
}

@font-face {
    font-family: "CroissantOne";
    src: url("./Croissant_One/CroissantOne-Regular.ttf");
}

@font-face {
    font-family: "Dosis";
    src: url("./Dosis/Dosis-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Dosis Light";
    src: url("./Dosis/static/Dosis-Light.ttf");
}

@font-face {
    font-family: "Dosis ExtraLight";
    src: url("./Dosis/static/Dosis-ExtraLight.ttf");
}

@font-face {
    font-family: "Forum";
    src: url("./Forum/Forum-Regular.ttf");
}

@font-face {
    font-family: "Gruppo";
    src: url("./Gruppo/Gruppo-Regular.ttf");
}

@font-face {
    font-family: "RedHatDisplay";
    src: url("./Red_Hat_Display/RedHatDisplay-VariableFont_wght.ttf");
}

@font-face {
    font-family: "RedHatDisplay Italic";
    src: url("./Red_Hat_Display/RedHatDisplay-Italic-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Sarabun";
    src: url("./Sarabun/Sarabun-Regular.ttf");
}

@font-face {
    font-family: "Tenor Sans";
    src: url("./Tenor_Sans/TenorSans-Regular.ttf");
}

@font-face {
    font-family: "Titillium Web";
    src: url("./Titillium_Web/TitilliumWeb-Regular.ttf");
}

@font-face {
    font-family: "Roboto";
    src: url("./Roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto Light";
    src: url("./Roboto/Roboto-Light.ttf");
}


