
.custom-button {
    position: relative;
    overflow: hidden;
    transition: background 400ms;
    //color: #000;
    //background-color: #397fea;
    //padding: 0.5rem 1rem;
    //font-family: 'Roboto', sans-serif;
    //font-size: 1rem;
    //outline: 0;
    //border: 0 solid transparent;
    //border-radius: 0.25rem;
    //box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5); /* black with 30% opacity */
    cursor: pointer;
}

.button-disabled {
    //background-color: #878da1;
    opacity: 0.5;
    //color: #cbcbcb;
}

.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.3);
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}