/* Buttons */
$primary-bg-color: #4775ba;
//$primary-bg-color: #397fea;
$primary-color: #f3f3f3;
$warning-bg-color: #af6d53;
//$warning-bg-color: #d5774d;
$warning-color: #f3f3f3;
$light-bg-color: #eeeeee;
$light-color: #1f1f1f;
$secondary-bg-color: #657177;
$secondary-color: #ffffff;
$danger-bg-color: #b33737;
//$danger-bg-color: #bb0000;
$danger-color: #ffffff;
$success-bg-color: #3b936d;
//$success-bg-color: #1a9459;
$success-color: #ffffff;
$extralight-bg-color: #ececec;
$extralight-color: #707070;
$outline-width: 3px;
$but-border-radius: 15px;

//#########################################
// Other colors
$base-color: #5b657a;
$border-color: #cbcbcb;
$title-color: $primary-bg-color;
//$title-color: steelblue;
$task-description-bg-color: #dddddd;
$task-description-bg-color-even: $task-description-bg-color;
$even-row-bg-color: #f6f6f6;

//##########################################
// Navbar colors
$navbar-link-color: $title-color;
$navbar-link-color-active: #0092cf;
$navbar-link-color-hover: #3f5897;
$navbar-link-color-active-hover: #2cb0ff;

/* NavBar */
.navbar-style {
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-color2);
  --bs-navbar-color: var(--navbar-text);
  --bs-navbar-hover-color: var(--navbar-text-hover);
}

.navbar-brand-style {
  color: var(--navbar-text) !important;
  font-family: Sarabun, sans-serif;
  --bs-navbar-brand-color: #{$navbar-link-color};
  --bs-navbar-brand-hover-color: #{$navbar-link-color-hover};
}

.navbar-brand-style:hover {
  color: var(--navbar-text-hover) !important;
}

.navbar-style a {
  //font-weight: bold;
}

.navbar-style button:focus {
  box-shadow: none;
}

.navbar-style .navbar-nav .nav-link.active {
  color: var(--navbar-text-active);
}

.navbar-style .navbar-nav .nav-link.active:hover {
  color: var(--navbar-text-active-hover);
}

.navbar-style .navbar-nav .nav-link {
  padding-left: 0.5rem;
  --bs-nav-link-color: var(--navbar-text);
  --bs-nav-link-hover-color: var(--navbar-text-hover);
}

.navbar-style .navbar-expand-md .navbar-collapse {
  border: none;
}

.navbar-style .navbar-collapse.collapse.show {
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  //border: 1px solid lightgray;
  border-radius: 0 0 5px 5px;
  //box-shadow: 0 1px 1px 1px lightgray;
  //transition: max-height 5s;
}

.navbar-exp-panel {
  background-color: var(--bg-color2);
  line-height: 15px;
  text-align: center;
}

.navbar-exp-padding {
  padding-bottom: 0.5rem;
}

.nav-svg {
  stroke: var(--navbar-text);
  fill: none;
}

.nav-link.active .nav-svg {
  stroke: var(--navbar-text-active);
}

.nav-link.active .nav-svg:hover {
  stroke: var(--navbar-text-active-hover);
}

.nav-link .nav-svg:hover {
  stroke: var(--navbar-text-hover);
}

/*###################################################################*/
/* Homepage styles */

.link-color {
  color: var(--link-text-color);
}

/*###################################################################*/
/* Task list styles */
.title {
  font-family: "Titillium Web", serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--page-title-color);
  //color: $base-color;
}

.border-gray {
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  border-radius: 4px;
}

.column-settings {
  //font-family: "Dosis Light", sans-serif;
  font-family: "Titillium Web", serif;
  //overflow-x: auto;
  padding-bottom: 15px;

}

.column-settings input {
  padding: 0 5px;
}

.column-settings div div div div div {
  margin: 0;
  padding: 0 2px;
  min-height: 20px;
}


/*###################################################################*/
/* Filter style */

.collapsible-style {
  box-sizing: content-box;
  overflow-y: hidden;
  transition: max-height 500ms ease-in-out;
}

.collapsible-x-scroll {
  overflow: auto;
}

.filter-signal {
  height: 1rem;
  width: 1rem;
  background-color: $primary-bg-color;
  //background-color: dodgerblue;
  border-radius: 50%;
}

.filter-text {
  font-size: 0.7rem;
  font-style: italic;
}


.shrink-container {
  display: flex; /* This makes children lay out horizontally. */
  flex-wrap: wrap; /* This allows children to wrap to the next line. */
}

.shrink-child {
  //flex: 1;                /* This allows children to grow and fill available space. */
  min-width: 150px; /* This sets a break point for when children should wrap. */
  //margin: 10px;           /* Optional: for spacing between the child divs. */
}

.shrink-child-2 {
  //flex: 1;                /* This allows children to grow and fill available space. */
  min-width: 120px; /* This sets a break point for when children should wrap. */
  //margin: 10px;           /* Optional: for spacing between the child divs. */
}

.shrink-child-flex {
  flex: 1;
  min-width: 150px;
}

.show-checkboxes {
  //height: 0;
  //max-height: 0;
  //overflow: hidden;
  //transition: max-height 300ms;
}

.icon {
  width: 18px;
  height: 18px;
  fill: var(--border-color);
}

.icon.active {
  //fill: var(--navbar-text-active);
  stroke: var(--page-title-color);
  stroke-width: 2px;
}

.check-label-1 {
  padding: 0 5px 0 5px;
  font-size: 12px;
}

/* Mobile view: Stacking children vertically */
//@media (max-width: 250px) {
//  .shrink-child {
//    min-width: 100%;   /* This makes each child take up the full width of its parent, causing a vertical layout. */
//  }
//}


.column-visible {
  transition: max-width 1s ease-in-out, opacity 1.5s ease-out, max-height 1s ease-in-out;
  overflow: hidden;
  max-width: 1000px; /* example max-width, adjust as needed */
  opacity: 1;
  max-height: 3rem;
}

.column-hidden {
  max-width: 0;
  min-width: 0 !important;
  opacity: 0;
  max-height: 1.2rem;
}


/*###################################################################*/
/* Fonts */

.monospace {
  font-family: "Roboto", monospace;
  margin-right: 0.5rem;
}

.heading-font1 {
  font-family: Sarabun, sans-serif;
  //font-family: Aclonica, serif;
  font-size: 28px;
  color: var(--page-title-color)
}

.heading-font2 {
  font-family: "Titillium Web", serif;
  //font-family: Aclonica, serif;
  font-size: 20px;
}

/*###################################################################*/
/* Table styles */

.table-container {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  //overflow: scroll;
  padding: 1rem 0.5rem;
}

.table-header {
  background-color: var(--table-header-bg-color);
  display: flex;
  color: var(--table-header-text-color);
  border-radius: 0.2rem 0.2rem 0 0;
  transition: background-color 1s, color 1s !important;
  //justify-content: space-evenly;
}

.table-footer {
  height: 0.8rem;
  display: flex;
  background-color: var(--table-header-bg-color);
  border-radius: 0 0 0.2rem 0.2rem;
}

.table-row {
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  box-sizing: content-box;
  //height: 2rem;
  //justify-content: space-evenly;
}

.table-row div {
  //flex-shrink: 1;
  //flex: 0 0 auto;
  //margin-right: 5px;
}

.table-cell {
  display: inline-block;
  box-sizing: border-box;
}

.table-row-bottom-line {
  border-bottom: 1px solid var(--border-color);
  transition: border-top-color 1s !important;
}

.table-row-top-line {
  border-top: 1px solid var(--border-color);
  transition: border-top-color 1s !important;
  //border-top: 1px solid $border-color;
  //height: 3rem;
}

.no-wrap {
  white-space: nowrap;
}

.row-height {
  height: 1.5rem;
}

.task-description {
  font-style: italic;
  //background-color: #555577;
  //color: darkslategray;
  //font-size: 0.9rem;
  //background-color: #e2e9ee;
  border-radius: 20px 0 20px 0;
  //outline: solid lightgray 1px;
  //padding: 0 0 0.2rem 0;
  //margin: 0.1rem 0 0.1rem 0;
  background-color: var(--bg-color3);
  transition: background-color 1s, color 1s, max-height 300ms !important;
  padding-left: 5%;
  max-height: 0;
  overflow: hidden;
}

.task-even {
  //background-color: $even-row-bg-color;
  background-color: var(--bg-color2);
  transition: background-color 1s, color 1s !important;
}

.starting-date {
  color: #5b657a;
}


/*###################################################################*/
/* Alignment */

.horizontal {
  display: flex;
  justify-content: center;
}

.center-all {
  justify-content: center;
  display: grid;
}

.center-vertically {
  display: flex;
  align-items: center;
}

//.center-vert {
//  display: grid;
//  align-items: center;
//}

.center-vertically-div > div {
  display: flex;
  align-items: center;
  padding-left: 0.4rem;
}

.center-horizontally {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.right-all {
  justify-content: right;
  text-align: right;
  display: grid;
}

.left-all {
  justify-content: left;
  text-align: left;
  display: grid;
}

//.flex-wrap-container {
//  display: flex;
//  flex-wrap: wrap;
//}
//
//.flex-wrap-content {
//  min-width: 100px;
//}

/*###################################################################*/
.login-plate {
  width: 300px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  //box-shadow: 0 0 10px darkgray;
  padding: 1.5rem;
  background-color: var(--bg-color2);
  //background-color: #fafafa;
}

.heading1 {
  font-size: 2rem;
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  text-align: center;
  color: var(--page-title-color);
  //color: #2b78c4;
  //color: #f0f7fd;
}

.small-link {
  font-size: 16px;
  color: var(--link-text-color);
  text-decoration: underline;
  font-family: "Titillium Web", sans-serif;
  cursor: pointer;
}

.no-label-input div {
  margin: 0 !important;
  padding: 0;
  min-height: 20px;
}

.no-label-input div div {
  height: 1.5rem;
}

.user-settings {
  margin: auto;
  max-width: 800px;
  min-width: 350px;
  width: 70%;
}

.announcements {
  //margin: auto;
  min-width: 280px;
}

.fade-in {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*###################################################################*/
/* Modal Window */
.modalwindow {
  //position: relative;
  position: fixed;
  //padding-top: 50px;
  padding: 50px 0 400px 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: baseline;
  justify-content: center;
  z-index: 1020;
  overflow: scroll;
}

.modalcontent {
  margin: auto;
  max-width: 500px;
  min-width: 350px;
  background-color: var(--bg-color);
  //background-color: #fff;
  border: #282c34 solid 1px;
  border-radius: 10px;
  padding: 10px;
}

.modalheader {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.modaltitle {
  padding-top: 10px;
  //margin: 0;
}

.modalbody {
  position: relative;
  padding: 0 20px 0 20px;
}

.modalfooter {
  padding: 10px;
  display: flex;
  justify-content: center;
}

/*###################################################################*/
/* Privacy policy modals */

.privacy-modal {
  background-color: rgba(200, 200, 200, 0.5);
  padding: 1rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0 0;
}

.details-modal {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  margin: auto;
  max-width: 700px;
  min-width: 360px;
}

.details-modal h2 {
  color: $base-color;
  font-weight: bold;
  text-align: center;
}

.details-modal h3 {
  font-weight: bold;
}

.details-modal * {
  text-align: justify;
}

/*###############################################################*/
/* Buttons */

.but {
  border: 0;
  outline: 0;
  //box-shadow: 0 0 0.1rem var(--but-box-shadow-color);
  border-radius: $but-border-radius;
  padding: 0.3rem 0.75rem 0.3rem 0.75rem;
  font-family: "Tenor Sans", serif;
  //font-weight: bold;
}


.but:active {
  box-shadow: none;
}

.but-sm {
  font-size: small;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  //border-radius: 0.1rem;
}

.but-primary {
  background-color: var(--primary-but-bg);
  color: var(--primary-but-text);
  transition: background-color 1s, color 1s !important;
}

.but-primary:focus {
  outline: $outline-width solid rgba($primary-bg-color, 0.5);
}

.but-warning {
  background-color: var(--warning-but-bg);
  color: var(--warning-but-text);
}

.but-warning:focus {
  outline: $outline-width solid rgba($warning-bg-color, 0.5);
}

.but-light {
  background-color: var(--light-but-bg);
  color: var(--light-but-text);
}

.but-light:focus {
  outline: $outline-width solid rgba($light-bg-color, 0.8);
}

.but-secondary {
  background-color: var(--secondary-but-bg);
  color: var(--secondary-but-text);
}

.but-secondary:focus {
  outline: $outline-width solid rgba($secondary-bg-color, 0.5);
}

.but-danger {
  background-color: var(--danger-but-bg);
  color: var(--danger-but-text);
}

.but-danger:focus {
  outline: $outline-width solid rgba($danger-bg-color, 0.5);
}

.but-success {
  background-color: var(--success-but-bg);
  color: var(--success-but-text);
}

.but-success:focus {
  outline: $outline-width solid rgba($success-bg-color, 0.5);
}

.but-extralight {
  background-color: var(--extralight-but-bg);
  color: var(--extralight-but-text);
  outline: none;
  border: 1px solid var(--border-color);
  //box-shadow: 0 0 0.1rem #dcdcdc;
}

.but-extralight:focus {
  outline: $outline-width solid rgba($extralight-bg-color, 0.8);
}

.but-rect {
  height: 1.2rem;
  width: 1.2rem;
  font-size: x-small;
  //border-radius: 10px;
  padding: 0;
}

.but-rect-vert {
  height: 2rem;
  width: 1.2rem;
  font-size: x-small;
  border-radius: 4px;
  padding: 0;
}

/*###################################################################*/
/* Error message */
.error-message {
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  font-style: italic;
  color: #d10000;
}

.error-text {
  font-style: italic;
  color: #d10000;
}

.error-text-sm {
  font-style: italic;
  color: #d10000;
  font-size: 0.7rem;
}

.message-text {
  font-style: italic;
  color: #989898;
}

.message-text-sm {
  font-style: italic;
  color: #989898;
  font-size: 0.7rem;
}

/*###################################################################*/
/* Dark mode */
body.dark-mode {
  background-color: #5b657a;
  color: #f0f0f0;
}

//###################################################################
// MarkDown styles
.md-output {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.md-output ol li {
  margin: 0 0 0.7rem 0;
}

.md-output ul li {
  margin: 0 0 0.5rem 0;
}

.md-output input:disabled {
  position: relative;
  appearance: none;
  padding-right: 20px;

  &:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 0;
    bottom: 0;
    background-color: var(--md-checkbox-bg-color);
    border: 1px solid var(--md-checkbox-border-color);
    border-radius: 3px;
  }

  &:after {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 0;
    bottom: 0;
    background-color: var(--md-checkbox-color);
    -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12.5L9 16.5L19 6.5"/></svg>');
    mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12.5L9 16.5L19 6.5"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;

  }

  &:checked {
    &:before {
      background-color: var(--md-checkbox-bg-color-checked);
    }
    &:after {
      opacity: 1;
    }
  }

}

//###################################################################
// Date classes
.end-date {
  color: var(--ok-text-color);
  //color: #54a868;
  font-weight: bold
}

.end-date-exceeded {
  color: var(--warning-text-color);
  //color: #d10000;
  font-weight: bold;
}

.color-green {
  color: #54a868;
}

.color-blue {
  color: $primary-bg-color;
  //color: #397fea;
}

.checkbox-custom:checked {
  background-color: $primary-bg-color !important;
}
